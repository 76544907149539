.mes-content {
    background: #fff;
    padding: 0 30px 30px;
}
.mes-box {
    padding: 0 24px;
}
.mes-box .box-top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.mes-box .title {
    font-size: 16px;
    font-weight: bold;
    width: 400px;
}
.mes-box .sub-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}
.mes-box .box-row {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
}
.mes-box .box-row .row-label{
    display: inline-block;
    margin-right: 20px;
    width: 100px;
    text-align: right;
}
.mes-box .box-row p {
    line-height: 1.2;
    margin-bottom: 4px;
}