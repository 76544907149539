.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ant-layout {
    height: auto;
    min-height: 100%;
}

.logo {
    background: url('../../images/logo1.png') no-repeat center 5px;
    height: 66px;
    background-size: contain;
}

.ant-layout-header {
    background: #fff !important;
}