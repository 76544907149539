@import '~antd/dist/antd.css';

#root {
  height: 100%;
}

/* .ant-layout {
  height: 100%;
} */
.ml-10 {
  margin-left: 10px;
}

.ant-layout-content {
  padding: 0 !important;
}

.is-show {
  display: block;
}

.is-hidden {
  display: none;
}

.ant-form {
  background: #fff !important;
  padding: 10px !important;
}

.ant-table-wrapper {
  margin-top: 20px !important;
}

.ant-page-header-ghost {
  background: #fff !important;

}

.ant-menu {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.left-span {
  width: 200px;
  text-align: right;
  margin-right: 10px;

}

.margin-15 {
  margin: 15px 0
}

.flex {
  display: flex;
}

.ant-picker {
  width: 100%;
}

/* 登录 */
.login-tab {
  width: 380px;
  height: 340px;
  margin: 10% 15% 0 0;
  background: #ffffff12;
  padding: 30px;
  border: 2px solid #3fb5d245;
}

.login-wrap {
  position: relative;
  background: url('./images/login.jpg') no-repeat center;
  height: 100%;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
}

.logo-txt {
  color: #fff;
  position: absolute;
  display: flex;
  top: 20px;
  left: 7%;
}

.logo-t1 {
  background: url('./images/logo_txt.png') no-repeat center;
  width: 220px;
  height: 50px;
  background-size: contain;
}

.login-form,
.login-form .ant-input-affix-wrapper,
.login-form .ant-input {
  background: transparent !important;
}

.ant-tabs-tab {
  color: #fff;
}

.login-form .ant-input-prefix,
.login-form .ant-input {
  color: #ffffff7a;

}

.login-form-button {
  width: 100%;
  border-radius: 20px;
  height: 42px;
  margin-top: 30px;
}

/* 首页*/
.home-title {
  border-left: 4px solid rgb(68, 161, 250);
  padding-left: 10px;
  font-size: 21px;
}

.home-h {
  background: url('./images/b0.jpg') no-repeat center;
  background-size: contain;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 42px;
  height: 20vh;
  line-height: 20vh;
}

.home-top {
  height: 20vh;
  padding: 10px;
  width: 32%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  color: #fff;
  font-size: 20px;
}

.b1 {
  background: url('./images/b1.png') no-repeat center;
  background-size: contain;
}

.b2 {
  background: url('./images/b2.png') no-repeat center;
  background-size: contain;
}

.b3 {
  background: url('./images/b3.png') no-repeat center;
  background-size: contain;
}

.b4 {
  background: url('./images/b4.png') no-repeat center;
  background-size: contain;
}

.b5 {
  background: url('./images/b5.png') no-repeat center;
  background-size: contain;
}

.b6 {
  background: url('./images/b6.png') no-repeat center;
  background-size: contain;
}

.b7 {
  background: url('./images/b7.jpg') no-repeat center;
  background-size: contain;
  height: 30vh;
}

.home-top h3 {
  font-size: 30px;
  color: #fff
}

.home-top div {
  padding-left: 140px;
  margin-top: -20px;
}

.home-bg {
  background: url('./images/liuc.png') no-repeat center;
  height: 38vh;
  background-size: contain;
}

/* 银行确权 */
.bank-right-title {
  border-left: 4px solid rgb(68, 161, 250);
  padding-left: 10px;
  font-size: 18px;
  font-weight: 700;
  margin: 20px 0;
}

.bank-block{
  padding-left: 20px;
  padding-bottom: 20px;
}