html,body {
    margin: 0;
    padding: 0;
    min-width: 1200px;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}